import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"session-item",class:{ selected: _vm.selected },attrs:{"data-id":_vm.session.sessionId},on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"line"}),_c(VCard,{staticClass:"position-relative pa-4",attrs:{"tile":"","elevation":"0"}},[(!_vm.completed)?_c(VProgressLinear,{attrs:{"top":"","left":"","stream":"","absolute":"","height":"5px","buffer-value":_vm.progress,"color":"darkgray darken-4"}}):_vm._e(),_c('div',{staticClass:"text-body-1 font-weight-bold text-truncate"},[_c('span',[_vm._v(_vm._s(_vm.session.sessionName))])]),_c('div',{staticClass:"text-subtitle-2 text-truncate"},[_c('span',[_vm._v(_vm._s(_vm._f("formatwithtime")(_vm.session.createdAt)))]),_c('span',{staticClass:"mx-2"},[_vm._v("·")]),_c('span',[_vm._v(_vm._s(_vm.length))]),_c('span',{staticClass:"checkbox-right"},[(_vm.session.hasFeedback)?_c(VTooltip,{attrs:{"top":"","nudge-bottom":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"green darken-2"}},on),[_vm._v(" mdi-checkbox-marked-circle-outline ")])]}}],null,false,248112243)},[_c('div',[_c('div',[_vm._v("Du hast Dein Feedback bereits abgegeben.")])])]):_c(VTooltip,{attrs:{"top":"","nudge-bottom":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"red darken-2"}},on),[_vm._v(" mdi-checkbox-blank-circle-outline ")])]}}])},[_c('div',[_c('div',[_vm._v("Bitte gib Dein Feedback ab!")])])])],1)]),_c('div',{staticClass:"align-center mt-2"},[_c('div',{staticClass:"d-flex flex-row overline",staticStyle:{"line-height":"1.2"}},[_c('div',[_vm._v(_vm._s(_vm.session.sharingUserName))]),_c(VSpacer),_c('div',{staticClass:"menu-button"},[(_vm.session.profilePicture)?_c('img',{attrs:{"src":_vm.session.profilePicture,"alt":"Profile Picture"}}):_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-account")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }