



































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Session, SharedSession } from "../../core/models/sessions";

// Components
import SessionDetails from "../../components/sessions/SessionDetails.vue";
import SubmittedSessionItem from "./components/SubmittedSessionItem.vue";

@Component({ components: { SubmittedSessionItem, SessionDetails } })
export default class Sessions extends Vue {
  @Getter("sessions/loading") loading!: boolean;
  @Getter("sessions/getSharedSessions") sharedSessions!: SharedSession[];
  @Getter("sessions/getSessions") sessions!: Session[];
  @Getter("sessions/removingSession") removingSession!: boolean;
  @Action("sessions/loadSharedSessions")
  loadSharedSessions!: () => Promise<void>;
  @Action("currentSession/setSession")
  setCurrentSession!: (s: Session) => void;
  @Action("sessions/getSingleSharedSession")
  getSession!: (id: number) => Promise<void>;

  async created() {
    await this.loadSharedSessions();
  }

  async selectSession(session: SharedSession) {
    console.log("selectSession", session);
    let selectedSession = this.sessions.find(x => x.ID === session.sessionId);
    if (!selectedSession) {
      await this.getSession(session.sessionId);
      selectedSession = this.sessions.find(x => x.ID === session.sessionId);
    }
    console.log("selectSession. selectedSession=", selectedSession);
    if (selectedSession) this.setCurrentSession(selectedSession);
  }
}
